import React from 'react'

export default function BoxCarousel({ authorName, authorTitle, rating, text, country }) {
  return (
    <div className="testimonial__item">
      <div className="testimonial__author">
        <div className="testimonial__author__icon">
          <i className="fa fa-quote-left"></i>
        </div>
        <div className="testimonial__author__text">
          <h5>{authorName} <span className={`flag-icon flag-icon-${country}`}></span></h5>
          <span>{authorTitle}</span>
        </div>
      </div>
      <div className="rating">
        {[...Array(rating)].map((_, index) => (
          <i key={index} className="fa fa-star"></i>
        ))}
      </div>
      <p>{text}</p>
    </div>
  )
}
