import React, { useState } from "react";
import contactPic from "../img/contact-pic.jpg";
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumbs";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import WhatsAppButton from "../components/WhatsAppButton";
import Popup from "../components/Popup";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState({ isOpen: false, message: "" });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.text();
      setPopup({ isOpen: true, message: result });
      // window.location.reload();
    } catch (error) {
      console.error("Error sending email:", error);
      setPopup({ isOpen: true, message: "Failed to send email." });
    } finally {
      setLoading(false); // Set loading back to false after form submission
    }
  };
  const closePopup = () => {
    setPopup({ isOpen: false, message: "" });
    window.location.reload(); // Reload the page after popup closes
  };

  return (
    <div>
      <Header />
      <Breadcrumb title="Contactez-nous" />
      <section className="contact spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="contact__widget">
                <div className="contact__widget__icon">
                  <i className="fa fa-map-marker"></i>
                </div>
                <div className="contact__widget__text">
                  <h5>Adresse</h5>
                  <p>Tunis</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="contact__widget">
                <div className="contact__widget__icon">
                  <i className="fa fa-phone"></i>
                </div>
                <div className="contact__widget__text">
                  <h5>Ligne d'assistance</h5>
                  <p>+216 50 127 188 • +216 52 208 265</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="contact__widget">
                <div className="contact__widget__icon">
                  <i className="fa fa-envelope"></i>
                </div>
                <div className="contact__widget__text">
                  <h5>Email</h5>
                  <p>contact@marrainetn.com</p>
                </div>
              </div>
            </div>
          </div>
          <div className="contact__content">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="contact__pic">
                  <img src={contactPic} alt="Contact" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="contact__form">
                  <h3>Formulaire de contact</h3>
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      name="name"
                      placeholder="Nom"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                    <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <input
                      type="text"
                      name="subject"
                      placeholder="Sujet"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                    <textarea
                      name="message"
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                    <button type="submit" className="site-btn">
                      {loading ? (
                        <span>
                          <i className="fa fa-spinner fa-spin"></i> Envoi en
                          cours...
                        </span>
                      ) : (
                        "Envoyer"
                      )}
                    </button>
                  </form>
                  <Popup
                    message={popup.message}
                    isOpen={popup.isOpen}
                    onClose={closePopup}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <WhatsAppButton phoneNumber="21650127188" />
      <ScrollToTop />
    </div>
  );
};

export default Contact;
