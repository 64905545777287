import React from "react";
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumbs";
import Welcome from "../components/Welcome";
import EngagementsQlt from "../components/EngagementsQlt";
import Chiffres from "../components/Chiffres";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import img1 from "../img/france_accreditation.png"
import img2 from "../img/Accreditation-Canada-Exemplary-Level-Seal.png"
import img3 from "../img/iso.png"
import Objectif from "../components/Objectif";
import Vision from "../components/Vision"
import ScrollToTop from "../components/ScrollToTop";
import WhatsAppButton from "../components/WhatsAppButton";


export default function About() {
  return (
    <div>
      <Header />
      <Breadcrumb title="À propos de nous" />
      <Welcome />
      <section className="services-page spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 order-lg-2">
              <div className="services__details">
                
                <div className="row">
                  <div className="col-sm-4">
                    <div className="services__details__item__pic">
                      <img src={img1} alt="" />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="services__details__item__pic">
                      <img src={img2} alt="" />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="services__details__item__pic">
                      <img src={img3} alt="" />
                    </div>
                  </div>
                </div>
                <div className="services__details__desc">
                  <p>
                  Nos organisations sont fières de détenir des certifications prestigieuses, notamment l'Accréditation France et l'Accréditation Canada, témoignant de leur conformité aux normes internationales les plus strictes. De plus, elles sont certifiées ISO, renforçant leur engagement envers la qualité, l'excellence et les meilleures pratiques mondiales. Ces accréditations reflètent une détermination constante à maintenir les plus hauts niveaux de performance, de fiabilité et de confiance dans la prestation de services.
                  </p>
                  
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Objectif/>
      <EngagementsQlt />
      <Vision/>
      <Chiffres />
      <Testimonials />
      <Footer />
      <WhatsAppButton phoneNumber="21650127188" />
      <ScrollToTop/>
    </div>
  );
}
