import React from "react";
import logo from "../img/footer-logo.png";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="footer__logo">
                <a href="#">
                  <img src={logo} alt="" />
                </a>
              </div>
            </div>

            <div className="col-lg-8 col-md-12">
              <div className="footer__social">
                <a href="https://www.facebook.com/profile.php?id=61567964554412">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="https://www.instagram.com/marrainetn/">
                  <i className="fa fa-instagram"></i>
                </a>
                <a href="https://www.tiktok.com/@marrainetn">
                  <i className="fa fa-tiktok"></i>
                </a>
                <a href="https://www.youtube.com/@Marraine-TN">
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-2 col-md-3 col-sm-6">
            <div className="footer__widget">
              <h5>Company</h5>
              <ul>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Departments</a>
                </li>
                <li>
                  <a href="#">Find a Doctor</a>
                </li>
                <li>
                  <a href="#">FAQ</a>
                </li>
                <li>
                  <a href="#">News</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6">
            <div className="footer__widget">
              <h5>Quick links</h5>
              <ul>
                <li>
                  <a href="#">Facial Fillers</a>
                </li>
                <li>
                  <a href="#">Breast Surgery</a>
                </li>
                <li>
                  <a href="#">Body Lifts</a>
                </li>
                <li>
                  <a href="#">Face & Neck</a>
                </li>
                <li>
                  <a href="#">Fat Reduction</a>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="footer__address">
              <h5>Contactez-nous</h5>
              <ul>
                <li>
                  <i className="fa fa-map-marker"></i>Tunis
                </li>
                <li>
                  <i className="fa fa-phone"></i>+216 50 127 188 • +216 52 208 265
                </li>
                <li>
                  <i className="fa fa-envelope"></i>contact@marrainetn.com
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-6">
            <div className="footer__map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d102239.55615109218!2d10.1432776!3d36.794882949999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd337f5e7ef543%3A0xd671924e714a0275!2sTunis!5e0!3m2!1sfr!2stn!4v1731537855794!5m2!1sfr!2stn"
                height="190"
                style={{ border: 0 }}
                allowFullScreen=""
                title="map"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="footer__copyright__text">
                <p>
                  &copy; {new Date().getFullYear()} Marraine Esthétiques | Tous
                  les droits sont réservés.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
