import React from "react";

export default function Vision() {
  return (
    <section className="chooseus spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-title">
                <h2>Chirurgie pour Tous</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-6 col-sm-6">
              <div className="chooseus__item">
                <p>
                Dans notre agence, nous croyons que tout le monde doit se sentir confiant et épanoui dans sa propre peau. Notre vision est axée sur la démocratisation de la chirurgie esthétique, car nous croyons que chacun mérite d'atteindre sa version de la perfection. Notre équipe dévouée est là pour vous guider à chaque étape, en fournissant des soins et un soutien personnalisés pour vous aider à atteindre vos objectifs.</p>
                
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
