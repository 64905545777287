import React from 'react'
import ci1 from "../img/icons/ci-1.png";
import ci2 from "../img/icons/ci-2.png";
import ci3 from "../img/icons/ci-3.png";
import ci4 from "../img/icons/ci-4.png";

export default function EngagementsQlt() {
  return (
    <section className="chooseus spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-title">
                <span>Pourquoi nous choisir ?</span>
                <h2>Nos Engagements de Qualité</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="chooseus__item">
                <img src={ci1} alt="Advanced equipment" />
                <h5>Équipement Avancé</h5>
                <p>
                  Nous utilisons des équipements de pointe pour garantir des
                  soins précis et efficaces. Notre technologie est constamment
                  mise à jour.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="chooseus__item">
                <img src={ci2} alt="Qualified doctors" />
                <h5>Chirurgiens Qualifiés</h5>
                <p>
                  Nos chirurgiens sont hautement qualifiés et spécialisés dans
                  leurs domaines respectifs. Ils assurent des soins sécurisés et
                  de haute qualité.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="chooseus__item">
                <img src={ci3} alt="Certified services" />
                <h5>Services Certifiés</h5>
                <p>
                  Nos services certifiés répondent aux normes les plus strictes
                  pour garantir des soins fiables et conformes aux standards
                  internationaux.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="chooseus__item">
                <img src={ci4} alt="Emergency care" />
                <h5>Soins Personnalisés</h5>
                <p>
                  Nous adaptons nos soins aux besoins spécifiques de chaque
                  patient. Cela garantit une attention unique, personnalisée, et
                  un bien-être optimal pour tous.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}
