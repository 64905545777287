import React from 'react';
import heroBg from "../img/hero-bg.jpg";
import logo1 from "../img/france_accreditation.png";
import logo2 from "../img/iso.png";
import logo3 from "../img/Accreditation-Canada-Exemplary-Level-Seal.png";

export default function HeroHome() {
  return (
    <section
        className="hero spad set-bg"
        style={{ backgroundImage: `url(${heroBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="hero__text">
                {/* <span>Marraine Esthétiques</span> */}
                <h2>Découvrez l'Excellence Médicale en Tunisie</h2>
                <div className="accreditation">
                  <span>Accréditées par:</span>
                  <div className="accreditation-logos">
                    <img src={logo1} alt="Accreditation 1" />
                    <img src={logo2} alt="Accreditation 2" />
                    <img src={logo3} alt="Accreditation 3" />
                  </div>
                </div>
                {/* <a href="#" className="primary-btn normal-btn">
                  Contact us
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}
