import React from "react";

export default function Objectif() {
  return (
    <section className="chooseus spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-title">
                <h2>Soutien aux Patients Vulnérables</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-6 col-sm-6">
              <div className="chooseus__item">
                <p>
                Soutien aux patients ayant subi un accident ou souffrant de malformations.
                Nous comprenons les défis uniques auxquels font face ceux qui ont été victimes d'un accident ou qui vivent avec une malformation. C'est pourquoi, chez Marraine Esthétiques, nous offrons des tarifs exceptionnels pour ces patients, en plus de tout mettre en œuvre pour leur offrir un environnement accueillant et bienveillant. Notre équipe est dévouée à fournir un accompagnement personnalisé, afin que chaque patient se sente soutenu et compris tout au long de son parcours de soins.
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
