import React from 'react';
import breadcrumbBg from '../img/breadcrumb-bg.jpg'; 

export default function Breadcrumb({title}) {
  return (
    <section 
      className="breadcrumb-option spad set-bg" 
      style={{ backgroundImage: `url(${breadcrumbBg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="breadcrumb__text">
              <h2>{title}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
