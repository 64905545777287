import React from "react";
import PropTypes from "prop-types";

export default function WhatsAppButton({ phoneNumber }) {
  const whatsappLink = `https://wa.me/${phoneNumber}`; // WhatsApp link with the provided phone number

  return (
    <a
      href={whatsappLink}
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa fa-whatsapp"></i> {/* Font Awesome WhatsApp Icon */}
    </a>
  );
}

// Define prop types for better reusability and validation
WhatsAppButton.propTypes = {
  phoneNumber: PropTypes.string.isRequired, // Phone number must be a string
};
