import React from 'react'
import bd1 from "../img/blog/details/bd-1.jpg";
import bd2 from "../img/blog/details/bd-2.jpg";
import bd3 from "../img/blog/details/bd-3.jpg";

export default function Chiffres() {
  return (
    <section>
        <div className="col-lg-12">
          <div className="blog__details__pic">
            <div className="row">
              <div className="col-sm-4">
                <img src={bd1} alt="Detail 1" />
              </div>
              <div className="col-sm-4">
                <img src={bd2} alt="Detail 2" />
              </div>
              <div className="col-sm-4">
                <img src={bd3} alt="Detail 3" />
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="blog__details__desc__more">
              <p>
                <span class="highlight">En Tunisie</span>,
                <span class="highlight"> 1,2 million</span> de procédures de
                chirurgie esthétique sont réalisées chaque année, témoignant
                d'un secteur en pleine expansion. Cette croissance repose sur
                l'expertise de chirurgiens hautement qualifiés, des standards de
                qualité stricts et des coûts compétitifs, attirant une clientèle
                locale et internationale . En collaboration avec <span className='highlight'>les trois plus
                grands chirurgiens plasticiens du pays </span>, nous garantissons à nos
                clients des soins de premier ordre, alliant sécurité, précision,
                et accès aux techniques chirurgicales les plus récentes pour des
                résultats optimaux et personnalisés.
              </p>
            </div>
          </div>
        </div>
      </section>
  )
}
