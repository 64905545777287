import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Devis from './pages/Devis'
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/font-awesome.min.css';
import './css/flaticon.css';
import './css/nice-select.css';
import './css/jquery-ui.min.css';
import './css/magnific-popup.css';
import './css/owl.carousel.min.css';
import './css/slicknav.min.css';
import './css/style.css';
import 'flag-icon-css/css/flag-icons.min.css';




function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path='/services' element={<Services/>} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/devis" element={<Devis />} />
      </Routes>
    </Router>
  );
}

export default App;
