import React, { useState } from "react";
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumbs";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import WhatsAppButton from "../components/WhatsAppButton";
import Popup from "../components/Popup";

const Devis = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    nationality: "",
    countryOfResidence: "",
    operationType: "",
    description: "",
    interventionDate: "",
    photos: null,
    medicalHistory: "",
    extras: "",
  });
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState({ isOpen: false, message: "" });

  const handleChange = (e) => {
    if (e.target.type === "file") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key === "photos") {
          for (let i = 0; i < formData.photos.length; i++) {
            formDataToSend.append("photos", formData.photos[i]);
          }
        } else {
          formDataToSend.append(key, formData[key]);
        }
      });
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(`${apiUrl}/send-devis-email`, {
        method: "POST",
        body: formDataToSend,
      });

      const result = await response.text();
      setPopup({ isOpen: true, message: result });

      // window.location.reload();
    } catch (error) {
      console.error("Error sending form data:", error);
      setPopup({ isOpen: true, message: "Failed to send Form." });
    }finally {
      setLoading(false); // Set loading back to false after form submission
    }
  };
  const closePopup = () => {
    setPopup({ isOpen: false, message: "" });
    window.location.reload(); // Reload the page after popup closes
  };

  return (
    <div>
      <Header />
      <Breadcrumb title="Devis Gratuit" />
      <section className="contact spad">
        <div className="container">
          <div className="contact__content">
            <div className="row">
              <div className="col-lg-12 col-md-6">
                <div className="contact__form">
                  <h3>Obtenir un devis gratuit</h3>
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Nom complet*"
                      value={formData.fullName}
                      onChange={handleChange}
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email*"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Numéro de téléphone* - Exemple : +33 123456789"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                    <input
                      type="text"
                      name="nationality"
                      placeholder="Nationalité*"
                      value={formData.nationality}
                      onChange={handleChange}
                      required
                    />
                    <input
                      type="text"
                      name="countryOfResidence"
                      placeholder="Pays de résidence*"
                      value={formData.countryOfResidence}
                      onChange={handleChange}
                      required
                    />
                    <input
                      type="text"
                      name="operationType"
                      placeholder="Type d'opération souhaitée*"
                      value={formData.operationType}
                      onChange={handleChange}
                      required
                    />
                    
                    <div>
                      <label>Joindre des photos*</label>
                      <input
                        type="file"
                        name="photos"
                        accept="image/*"
                        multiple
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label>Date souhaitée pour l'intervention</label>
                      <input
                        type="date"
                        name="interventionDate"
                        value={formData.interventionDate}
                        onChange={handleChange}
                      />
                    </div>
                    <textarea
                      name="description"
                      placeholder="Décrivez vos besoins ou attentes (facultatif)"
                      value={formData.description}
                      onChange={handleChange}
                    />
                    <div>
                      <label>
                        Avez-vous des antécédents médicaux ou des allergies ?
                        (facultatif)
                      </label>
                      <textarea
                        name="medicalHistory"
                        placeholder="Exemple : allergie à certains médicaments"
                        value={formData.medicalHistory}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label>
                        Extras : Souhaitez-vous un séjour, un hôtel, ou des
                        sorties ? (facultatif)
                      </label>
                      <textarea
                        name="extras"
                        placeholder="Exemple : 3 nuits d'hôtel, visites touristiques"
                        value={formData.extras}
                        onChange={handleChange}
                      />
                    </div>
                    <button type="submit" className="site-btn">
                      {loading ? (
                        <span>
                          <i className="fa fa-spinner fa-spin"></i> Envoi en
                          cours...
                        </span>
                      ) : (
                        "Envoyer"
                      )}
                    </button>
                  </form>
                  <Popup
                    message={popup.message}
                    isOpen={popup.isOpen}
                    onClose={closePopup}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <WhatsAppButton phoneNumber="21650127188" />
      <ScrollToTop />
    </div>
  );
};

export default Devis;
