import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BoxCarousel from "./BoxCarousel"; // Import the Box component

export default function Testimonials() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      authorName: "Sarah",
      authorTitle: "Businesswoman",
      rating: 5,
      text: "كانت تجربتي مع وكالة ماران إستيتيك لا تُنسى! الخدمة كانت متميزة والفريق احترافي للغاية. نتائج العملية فعلا كانت أحسن من المتوقّع",
      country: "sa", // Country code for Flag Icon CSS
    },
    {
      authorName: "Patrice",
      authorTitle: "Entrepreneur",
      rating: 5,
      text: "Mon expérience avec Marraine Esthétiques  a été exceptionnelle! Le service était irréprochable et l'équipe très compétente. Merci Marraine",
      country: "fr",
    },
    {
      authorName: "Teresa",
      authorTitle: "Designer",
      rating: 5,
      text: "J'ai eu une expérience formidable avec Marraine Esthétiques . Le service était de haute qualité et l'équipe très qualifiée. Je les recommande vivement à quiconque a besoin de soins médicaux à l'étranger.",
      country: "ci",
    },
    {
      authorName: "Lana",
      authorTitle: "Photographer",
      rating: 4,
      text: "My experience with 'Marraine Esthétiques' was simply wonderful! The service was top-notch, and the team very professional. The results of my aesthetic surgery exceeded my expectations, and I couldn't be happier. I highly recommend them to anyone seeking medical assistance abroad.",
      country: "de",
    },
  ];

  return (
    <section className="testimonials spad">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="section-title">
              <span>Témoignages</span>
              <h2>Clients satisfaits</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <Slider {...settings} className="testimonial__slider">
            {testimonials.map((testimonial, index) => (
              <BoxCarousel
                key={index}
                authorName={testimonial.authorName}
                authorTitle={testimonial.authorTitle}
                rating={testimonial.rating}
                text={testimonial.text}
                country={testimonial.country}
              />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}
