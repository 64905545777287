import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumbs";
import BoxPricing from "../components/BoxPricing";
import ScrollToTop from "../components/ScrollToTop";
import WhatsAppButton from "../components/WhatsAppButton";

const pricingData = [
  {
    SurgeryName: "Chirurgie esthétique du visage",
    services: [
      { name: "Lifting cervico facial", price: "À partir de 3.300 €" },
      { name: "Lifting malaire", price: "À partir de 2.450 €" },
      { name: "Lifting frontal", price: "À partir de 2.450 €" },
      { name: "Lifting complet du visage", price: "À partir de 3.450 €" },
      { name: "Rhinoplastie simple", price: "À partir de 2.350 €" },
      { name: "Rhinoplastie et septoplastie", price: "À partir de 2.700 €" },
      { name: "Rhinoplastie ethnique", price: "À partir de 2.700 €" },
      { name: "Rhinoplastie ultrasonique", price: "À partir de 3.650 €" },
      {
        name: "Rhinoplastie ultrasonique ethnique",
        price: "À partir de 4.250 €",
      },
      { name: "Chirurgie 2 paupières", price: "À partir de 1.800 €" },
      { name: "Chirurgie 4 paupières", price: "À partir de 2.000 €" },
      { name: "Lip lift (chirurgie des lèvres)", price: "À partir de 3.100 €" },
    ],
  },
  {
    SurgeryName: "Chirurgie esthétique combinée",
    services: [
      {
        name: "Lifting complet du visage + chirurgie 2 paupières",
        price: "À partir de 3.850 €",
      },
      {
        name: "Lifting complet du visage + chirurgie 4 paupières",
        price: "À partir de 3.950 €",
      },
      {
        name: "Lifting des seins + pose de prothèses rondes",
        price: "À partir de 3.200 €",
      },
      {
        name: "Lifting des seins + pose de prothèses anatomiques",
        price: "À partir de 3.400 €",
      },
      {
        name: "Réduction mammaire + lifting pour remonter les seins",
        price: "À partir de 3.300 €",
      },
      {
        name: "Abdominoplastie + liposuccion du ventre",
        price: "À partir de 3.200 €",
      },
      {
        name: "Abdominoplastie + traitement du diastasis + liposuccion complète",
        price: "À partir de 3.700 €",
      },
      {
        name: "Abdominoplastie + liposuccion complète",
        price: "À partir de 3.300 €",
      },
      {
        name: "Liposuccion complète + lipofilling seins ou fesses",
        price: "À partir de 3.300 €",
      },
      {
        name: "Liposuccion 360 + abdominoplastie",
        price: "À partir de 3.650 €",
      },
    ],
  },
  {
    SurgeryName: "Chirurgie esthétique corps & silhouette",
    services: [
      { name: "Liposuccion vaser", price: "À partir de 3.650 €" },
      { name: "Abdominoplastie", price: "À partir de 2.450 €" },
      { name: "Abdominal etching", price: "À partir de 4.100 €" },
      {
        name: "Augmentation fesses par prothèses",
        price: "À partir de 3.300 €",
      },
      {
        name: "BBL (Liposuccion + injection graisse dans le fessier)",
        price: "À partir de 3.300 €",
      },
      { name: "Lifting des bras", price: "À partir de 1.950 €" },
      { name: "Lifting des fesses", price: "À partir de 2.350 €" },
      { name: "Lifting des cuisses", price: "À partir de 2.150 €" },
      { name: "Abdominoplastie circulaire", price: "À partir de 3.300 €" },
      { name: "Lifting du dos", price: "À partir de 2.150 €" },
    ],
  },
  {
    SurgeryName: "Chirurgie esthétique des seins",
    services: [
      {
        name: "Augmentation mammaire prothèses rondes",
        price: "À partir de 2.750 €",
      },
      {
        name: "Augmentation mammaire prothèses anatomiques",
        price: "À partir de 2.950 €",
      },
      { name: "Lifting des seins", price: "À partir de 2.450 €" },
      { name: "Réduction mammaire", price: "À partir de 2.450 €" },
      { name: "Lipofilling seins", price: "À partir de 3.300 €" },
      { name: "Gynécomastie", price: "À partir de 2.450 €" },
      { name: "Implants pectoraux", price: "À partir de 3.300 €" },
    ],
  },

  {
    SurgeryName: "Liposuccion",
    services: [
      { name: "Liposuccion 1 zone", price: "À partir de 2.000 €" },
      { name: "Liposuccion du cou", price: "À partir de 2.150 €" },
      { name: "Liposuccion menton", price: "À partir de 2.150 €" },
      { name: "Liposuccion ventre", price: "À partir de 2.250 €" },
      { name: "Liposuccion 360", price: "À partir de 2.650 €" },
      {
        name: "Liposuccion complète plusieurs zones",
        price: "À partir de 2.400 €",
      },
      { name: "Liposuccion Vaser", price: "À partir de 3.650 €" },
      { name: "Liposuccion full hd 5D", price: "À partir de 4.000 €" },
      { name: "Liposuccion j plasma", price: "À partir de 3.650 €" },
    ],
  },
  {
    SurgeryName: "Rhinoplastie",
    services: [
      { name: "Rhinoplastie primaire", price: "À partir de 2.350 €" },
      { name: "Rhinoplastie ethnique", price: "À partir de 2.700 €" },
      { name: "Rhinoplastie ultrasonique", price: "À partir de 3.650 €" },
      {
        name: "Rhinoplastie ultrasonique ethnique",
        price: "À partir de 4.250 €",
      },
      {
        name: "Rhinoplastie avec septoplastie (correction déviation cloison)",
        price: "À partir de 2.700 €",
      },
    ],
  },

  {
    SurgeryName: "Opérations Hommes",
    services: [
      { name: "Ovale visage", price: "À partir de 2.950 €" },
      { name: "Rides du front par lifting", price: "À partir de 2.450 €" },
      { name: "Lifting complet visage", price: "À partir de 3.450 €" },
      { name: "Rhinoplastie", price: "À partir de 2.350 €" },
      {
        name: "Chirurgie nez avec redressement cloison nasale",
        price: "À partir de 2.700 €",
      },
      { name: "Rhinoplastie nez ethniques", price: "À partir de 2.700 €" },
      {
        name: "Chirurgie paupières, blépharoplastie",
        price: "À partir de 1.800 €",
      },
      { name: "Gynécomastie", price: "À partir de 2.350 €" },
      { name: "Pénoplastie", price: "Selon indications" },
      {
        name: "Greffe de cheveux ou de barbe",
        price: "En fonction du nombre de greffons nécessaires",
      },
      {
        name: "Liposuccion ventre et poignées d'amour",
        price: "À partir de 2.250 €",
      },
      { name: "Liposuccion plus de 3 zones", price: "À partir de 2.400 €" },
      { name: "Liposuccion vaser", price: "À partir de 3.000 €" },
      { name: "Abdominal etching", price: "À partir de 4.100 €" },
      {
        name: "Abdominoplastie + liposuccion du ventre",
        price: "À partir de 3.200 €",
      },
    ],
  },
  {
    SurgeryName: "Greffe de cheveux",
    services: [
      {
        name: "Greffe de cheveux FUE",
        price: "À partir de 2.400 € pour 1500 à 3000 greffons. À partir de 2.800 € pour 3500 à 5000 greffons."
      },
      {
        name: "Greffe de cheveux DHI",
        price: "À partir de 3.200 € pour 1500 à 3000 greffons. À partir de 3.600 € pour 3500 à 5000 greffons"
      }
    ]
  },  
  {
    "SurgeryName": "Dentisterie esthétique",
    "services": [
      {
        "name": "Facettes dentaires",
        "price": "À partir de 350 € par facette"
      },
      {
        "name": "Blanchiment dentaire",
        "price": "À partir de 100 €"
      },
      {
        "name": "Prothèse dentaire - Couronne en céramique",
        "price": "Entre 300 € - 500 €"
      },
      {
        "name": "Prothèse dentaire - Couronne en zircone",
        "price": "Entre 400 € - 600 €"
      },
      {
        "name": "Prothèse dentaire - Prothèse partielle",
        "price": "Entre 500 € - 800 €"
      },
      {
        "name": "Prothèse dentaire - Prothèse complète",
        "price": "Entre 800 € - 1200 €"
      },
      {
        "name": "Implant dentaire",
        "price": "Entre 700 € - 1200 €"
      }
    ]
  },  
  {
    SurgeryName: "Fécondation in-vitro (FIV)",
    services: [
      { name: "Traitement complet FIV", price: "À partir de 2.900 €" },
    ],
  },
];

export default function Services() {
  return (
    <div>
      <Header />
      <Breadcrumb title="Nos Services & Tarifs" />
      <section className="pricing spad">
        <div className="container">
          <div className="row">
            {pricingData.map((box, index) => (
              <BoxPricing
                key={index}
                SurgeryName={box.SurgeryName}
                services={box.services}
              />
            ))}
          </div>
        </div>
      </section>
      <Footer />
      <WhatsAppButton phoneNumber="21650127188" />
      <ScrollToTop/>
    </div>
  );
}
