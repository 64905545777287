import React from 'react';
import servicesBg from '../img/services-bg.jpg';
import { Link } from 'react-router-dom';


export default function OverviewServices() {
  return (
    <section className="services spad set-bg" style={{ backgroundImage: `url(${servicesBg})` }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-6">
            <div className="section-title">
              <span>Nos services</span>
              <h2>Offre pour vous</h2>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="services__btn">
            <Link to="/services">
              <a href="#" className="primary-btn">Nos Services & Tarifs</a></Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="services__item">
              <div className="services__item__icon">
                <span className="flaticon-010-beauty"></span>
              </div>
              <div className="services__item__text">
                <h5>Rhinoplastie</h5>
                <p>Remodelage du nez pour améliorer l'esthétique ou la respiration.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="services__item">
              <div className="services__item__icon">
                <span className="flaticon-027-beauty"></span>
              </div>
              <div className="services__item__text">
                <h5>Lifting du visage</h5>
                <p>Raffermissement de la peau pour un visage plus jeune.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="services__item">
              <div className="services__item__icon">
                <span className="flaticon-031-anatomy"></span>
              </div>
              <div className="services__item__text">
                <h5>Augmentation mammaire</h5>
                <p>Implantation pour augmenter la taille et la forme des seins.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="services__item">
              <div className="services__item__icon">
                <span className="flaticon-008-abdominoplasty"></span>
              </div>
              <div className="services__item__text">
                <h5>Liposuccion</h5>
                <p>Intervention pour éliminer les graisses localisées et affiner la silhouette.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
