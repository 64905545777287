import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import logo from "../img/logo.png";
import minilogo from "../img/mini logo.png";
import textLogo from "../img/text_logo.png"; // Import the new text logo

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const location = useLocation();

  return (
    <header className="header">
      <div className="header__top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ul className="header__top__left">
                <li>
                  <i className="fa fa-phone"></i> +216 50 127 188 • +216 52 208 265
                </li>
                <li>
                  <i className="fa fa-map-marker"></i> Tunis
                </li>
                <li>
                  <i className="fa fa-clock-o"></i> 24h/7j
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <div className="header__top__right">
                <a href="https://www.facebook.com/profile.php?id=61567964554412">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="https://www.instagram.com/marrainetn/">
                  <i className="fa fa-instagram"></i>
                </a>
                <a href="https://www.tiktok.com/@marrainetn">
                  <i className="fa fa-tiktok"></i>
                </a>
                <a href="https://www.youtube.com/@Marraine-TN">
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <div className="header__logo">
              {/* Mini logo for laptops and text logo for mobile */}
              <Link to="/">
                <img src={minilogo} alt="Mini Logo" className="mini-logo" />
                <img src={textLogo} alt="Text Logo" className="text-logo" />
              </Link>
            </div>
          </div>
          <div
            className={`col-lg-10 header__menu__option ${
              isMenuOpen ? "open" : ""
            }`}
          >
            <span className="close-menu" onClick={toggleMenu}>
              &times; {/* Close button */}
            </span>
            <nav className="header__menu">
              <ul>
                <li className={location.pathname === "/" ? "active" : ""}>
                  <Link to="/">Accueil</Link>
                </li>
                <li className={location.pathname === "/about" ? "active" : ""}>
                  <Link to="/about">À propos de nous</Link>
                </li>
                <li
                  className={location.pathname === "/services" ? "active" : ""}
                >
                  <Link to="/services">Nos Services & Tarifs</Link>
                </li>
                <li
                  className={location.pathname === "/contact" ? "active" : ""}
                >
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </nav>
            <div className="header__btn">
              <Link to="/devis">
                <span className="primary-btn normal-btn">Devis Gratuit</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="canvas__open" onClick={toggleMenu}>
          <i className="fa fa-bars"></i>
        </div>
      </div>
    </header>
  );
}
