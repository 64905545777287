import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Welcome from "../components/Welcome";
import EngagementsQlt from "../components/EngagementsQlt";
import Chiffres from "../components/Chiffres";
import HeroHome from "../components/HeroHome";
import OverviewServices from "../components/OverviewServices";
import { Link } from "react-router-dom";
import Objectif from "../components/Objectif";
import img from "../img/tun.jpg"
import ScrollToTop from "../components/ScrollToTop";
import WhatsAppButton from "../components/WhatsAppButton";

export default function Home() {
  return (
    <div>
      <Header />
      <HeroHome />
      <Welcome />
      <Objectif />
      
        <div className="col-lg-12">
          <div className="img_tun">
            <img src={img} alt="" />
          </div>
        </div>
      <EngagementsQlt />
      <Chiffres />
      <OverviewServices />

      <section className="chooseus spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-title">
                <h2>Votre projet esthétique personnalisé commence ici</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-6 col-sm-6">
              <div className="chooseus__item">
                <p>
                  Vous envisagez une transformation esthétique ? Notre équipe de
                  Marraine Esthétiques est prête à vous accompagner ! Profitez
                  d'une évaluation personnalisée et de conseils d'experts
                  adaptés à vos attentes. Nous sommes là pour répondre à toutes
                  vos questions et vous fournir un devis sur mesure. Pourquoi
                  attendre pour atteindre la perfection ?
                </p>
                <Link to="/devis">
                  <a href="#" className="primary-btn normal-btn">
                    Obtenir votre Devis Gratuit
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <WhatsAppButton phoneNumber="21650127188" />
      <ScrollToTop/>
    </div>
  );
}
