import React from 'react';

export default function BoxPricing({SurgeryName, services }) {
  return (
    <div className="col-lg-4 col-md-6 col-sm-6">
      <div className="pricing__item">
        <div className="pricing__item__title">
          <h3>
            {SurgeryName} <span></span>
          </h3>
        </div>
        <ul>
          {services.map((service, index) => (
            <li key={index}>
              <h6>{service.name}</h6>
              <span>{service.price}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
